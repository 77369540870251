const PastExperience = () => {
  return (
    <div className="mx-auto max-w-screen-lg px-3 py-6">
      <div className="mb-6 text-2xl font-bold">
        Past{" "}
        <span className="bg-gradient-to-br from-sky-500 to-cyan-400 bg-clip-text text-transparent">
          Experience
        </span>
      </div>

      <div className="flex flex-col gap-6">
        <ol className="relative border-l border-gray-200 dark:border-gray-700">
          <li className="mb-10 ml-4">
            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time className="mb-1 text-sm font-normal leading-none text-gray-500">
              Mar 2022 - Present
            </time>
            <h3 className="text-lg font-semibold">
              Freelance Software Engineer
            </h3>
          </li>

          <li className="mb-10 ml-4">
            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time className="mb-1 text-sm font-normal leading-none text-gray-500">
              Mar 2020 - Mar 2022
            </time>
            <h3 className="text-lg font-semibold">
              Software Developer - Small Satellites at the{" "}
              <a
                href="https://aerospace.org/"
                className="underline hover:text-cyan-400"
              >
                Aerospace Corporation
              </a>
            </h3>
            <ul className="list-none text-base font-normal text-gray-400">
              <li>
                Developed ground support systems for satellite communication
              </li>
              <li>
                - Web application development with React.js, Django, Centos7
                (Linux), Nginx
              </li>
              <li>- Data Parsing with python, MATLAB, pandas, matplotlib</li>
              <li>- Interfaced C++ to Python code</li>
              <li>- Containerized applications with Docker</li>
              <li>- Developed Mission Planning software with MATLAB</li>
            </ul>
          </li>

          <li className="mb-10 ml-4">
            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time className="mb-1 text-sm font-normal leading-none text-gray-500">
              May 2019 - Feb 2020
            </time>
            <h3 className="text-lg font-semibold">
              Undergraduate Research Assistant -{" "}
              <a
                href="https://twitter.com/uvmcomplexity?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                className="underline hover:text-cyan-400"
              >
                Vermont Complex Systems Center
              </a>
            </h3>
            <ul className="list-none text-base font-normal text-gray-400">
              <li>
                Assisted Professor Josh Bongard in the ongoing development of{" "}
                <a
                  href="https://www.twitch.tv/twitchplaysrobotics"
                  className="underline hover:text-gray-100"
                >
                  Twitch Plays Robotics
                </a>
              </li>
              <li>
                - Developed and implemented new experimental designs with Python
              </li>
              <li>
                - Analyzed data to drive new experimental direction using numpy,
                pandas, matplotlib
              </li>
              <li>
                - Doubled crowd-sourced experiment participation by more than
                2-fold.
              </li>
              <li>
                - Worked with pyrosim, a python interface for ODE, to design
                robots and implement neural networks.
              </li>
              <li>- Version control using Github</li>
            </ul>
          </li>

          <li className="mb-10 ml-4">
            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time className="mb-1 text-sm font-normal leading-none text-gray-500">
              Jul 2018 - Sept 2018
            </time>
            <h3 className="text-lg font-semibold">
              Software Engineering Intern
            </h3>
            <ul className="list-none text-base font-normal text-gray-400">
              <li>
                Developed search interface for{" "}
                <a
                  href="http://degree-compass.com/search"
                  className="underline hover:text-gray-100"
                >
                  degree-compass.com
                </a>
              </li>
              <li>
                - Created a university search application using Django and React
              </li>
              <li>
                - Found and wrote scripts to clean public database containing
                university information
              </li>
              <li>- Merged search application with existing web pages</li>
              <li>- Deployed site to Heroku</li>
            </ul>
          </li>

          <li className="ml-4">
            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
            <time className="mb-1 text-sm font-normal leading-none text-gray-500">
              2015 - 2019
            </time>
            <h3 className="text-lg font-semibold">
              University of Vermont - BS Computer Science
            </h3>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PastExperience;
