const Projects = () => {
  const techButtonBaseStyle =
    "rounded-md px-2 py-1 text-xs font-semibold flex items-center";
  const techButtonStyles = {
    webApp: techButtonBaseStyle + "  bg-lime-400 text-lime-900",
    javascript: techButtonBaseStyle + " bg-fuchsia-400 text-fuchsia-900",
    react: techButtonBaseStyle + " bg-sky-400 text-sky-900",
    html_css: techButtonBaseStyle + " bg-rose-400 text-rose-900",
    firebase: techButtonBaseStyle + " bg-yellow-400 text-yellow-900",
    ios: techButtonBaseStyle + " bg-slate-400 text-slate-900",
    swift: techButtonBaseStyle + " bg-orange-400 text-orange-900",
    python: techButtonBaseStyle + " bg-teal-400 text-teal-900",
    tradestation: techButtonBaseStyle + " bg-violet-400 text-violet-900",
    tradingView: techButtonBaseStyle + " bg-blue-400 text-blue-900",
  };

  return (
    <div className="mx-auto max-w-screen-lg px-3 py-6">
      <div className="mb-6 text-2xl font-bold">
        Recent{" "}
        <span className="bg-gradient-to-br from-sky-500 to-cyan-400 bg-clip-text text-transparent">
          Projects
        </span>
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-center gap-x-8 rounded-md bg-slate-800 p-3 md:flex-row">
          <div className="shrink-0 h-24 w-24">
            <a href="https://www.selectdesign.com/brandhub/">
              <img
                className="h-24 w-24 hover:translate-y-1"
                loading="lazy"
                src={require("../assets/select-design-logo.jpeg")}
                alt="Select Logo"
              />
            </a>
          </div>
          <div>
            <div className="flex flex-col items-center gap-y-2 md:flex-row">
              <a
                className="hover:text-cyan-400"
                href="https://www.selectdesign.com/brandhub/"
              >
                <div className="text-xl font-semibold">Brandhub & RTA</div>
              </a>

              <div className="ml-3 flex gap-1 sm:gap-2">
                <div className={techButtonStyles["webApp"]}>Web App</div>
                <div className={techButtonStyles["react"]}>React.js</div>
                <div className={techButtonStyles["javascript"]}>Javascript</div>
                <div className={techButtonStyles["html_css"]}>HTML/CSS</div>
              </div>
            </div>
            <p className="mt-3 text-gray-400">
              I work with a team at{" "}
              <a
                href="https://www.selectdesign.com/"
                className="underline hover:text-gray-100"
              >
                Select Design
              </a>{" "}
              to develop two B2B applications which Select Design's clients use
              to order and request quotes for their physical marketing displays,
              as well as manage nationwide advertisement campaigns.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-x-8 rounded-md bg-slate-800 p-3 md:flex-row">
          <div className="shrink-0 h-24 w-24">
            <a href="https://radientanalytics.com/firm/adv/sphere-capital-group-llc-300969">
              <img
                className="h-24 w-24 hover:translate-y-1"
                src={require("../assets/stock-market.png")}
                loading="lazy"
                alt="finance"
              />
            </a>
          </div>
          <div>
            <div className="flex flex-col items-center gap-y-2 md:flex-row">
              <a
                className="hover:text-cyan-400"
                href="https://radientanalytics.com/firm/adv/sphere-capital-group-llc-300969"
              >
                <div className="text-xl font-semibold">
                  Financial Tools for Hedge Fund
                </div>
              </a>
              <div className="ml-3 flex gap-1 sm:gap-2">
                <div className={techButtonStyles["python"]}>Python</div>
                <div className={techButtonStyles["tradestation"]}>
                  Tradestation
                </div>
                <div className={techButtonStyles["tradingView"]}>
                  Trading View
                </div>
              </div>
            </div>
            <p className="mt-3 text-gray-400">
              Created Python application which parses Fidelity trading logs to
              determine P&L's, exposure etc.
            </p>
            <p className="mt-1 text-gray-400">
              Developed indicators and alerts for Tradestation and Trading View
              trading platforms.
            </p>
            <p className="mt-1 text-gray-400">
              Data analysis on stock market data to assess market strategies and
              develop a risk management tool.
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-x-8 rounded-md bg-slate-800 p-3 md:flex-row">
          <div className="shrink-0 h-24 w-24">
            <a href="https://polyglot.club/#/">
              <img
                className="h-24 w-24 hover:translate-y-1"
                src={require("../assets/polyglot-club-logo.png")}
                loading="lazy"
                alt="polyglot club"
              />
            </a>
          </div>
          <div>
            <div className="flex flex-col items-center gap-y-2 md:flex-row">
              <a
                className="hover:text-cyan-400"
                href="https://polyglot.club/#/"
              >
                <div className="text-xl font-semibold">Polyglot.club</div>
              </a>
              <div className="md:ml-3 flex gap-1 sm:gap-2">
                <div className={techButtonStyles["webApp"]}>Web App</div>
                <div className={techButtonStyles["react"]}>React.js</div>
                <div className={techButtonStyles["firebase"]}>Firebase</div>
                <div className={techButtonStyles["javascript"]}>Javascript</div>
                <div className={techButtonStyles["html_css"]}>HTML/CSS</div>
              </div>
            </div>
            <p className="mt-3 text-gray-400">
              Prototype of a forum built using react to support{" "}
              <a
                className="underline hover:text-gray-100"
                href="https://lingidy.com/"
              >
                Lingidy
              </a>
              , a language learning app for iPhone developed by{" "}
              <a
                className="underline hover:text-gray-100"
                href="https://vidalingua.com/"
              >
                Vidalingua
              </a>
              .
            </p>
          </div>
        </div>

        <div className="flex flex-col items-center gap-x-8 rounded-md bg-slate-800 p-3 md:flex-row">
          <div className="shrink-0 h-24 w-24">
            <a href="https://www.selectdesign.com/">
              <img
                className="h-24 w-24 hover:translate-y-1"
                src={require("../assets/beacon.png")}
                loading="lazy"
                alt="beacon"
              />
            </a>
          </div>
          <div>
            <div className="flex flex-col items-center gap-y-2 md:flex-row">
              <a
                className="hover:text-cyan-400"
                href="https://www.selectdesign.com/"
              >
                <div className="text-xl font-semibold">
                  Beacon Detection iPhone App
                </div>
              </a>

              <div className="ml-3 flex gap-1 sm:gap-2">
                <div className={techButtonStyles["swift"]}>Swift</div>
                <div className={techButtonStyles["ios"]}>iOS</div>
                <div className={techButtonStyles["react"]}>React.js</div>
              </div>
            </div>
            <p className="mt-3 text-gray-400">
              Developed a iOS bluetooth beacon detection app for a company in
              Burlington, VT. I am also integrating data from the app with a
              react app the company uses internally.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
