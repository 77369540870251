import { useSpring, animated } from "react-spring";

const IntroSection = () => {
  // Define animations for the heading and social media icons

  const socialMediaIconsAnimation = useSpring({
    from: { opacity: 0, transform: "translateX(-20px)" },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 250 }, // Delay the social media icons animation
  });

  const delayedSocialMediaIconsAnimation = useSpring({
    ...socialMediaIconsAnimation,
    config: { delay: 250 }, // Apply a delay to this animation
  });

  return (
    <div className="mx-auto max-w-screen-lg px-3 py-6">
      <div className="flex flex-col items-center md:flex-row md:justify-between md:gap-x-24">
        <div>
          {/* Animate the heading */}
          <h1 className="text-3xl font-bold">
            Hi there, I'm{" "}
            <span className="bg-gradient-to-br from-sky-500 to-cyan-400 bg-clip-text text-transparent">
              Jean-Baptiste
            </span>{" "}
            👋
          </h1>
          {/* Animate the paragraph */}
          <p className="mt-6 text-xl leading-9">
            My name is Jean-Baptiste and I am a software engineer based in the
            United States. My primary focus is full stack web development, but I
            have also built a number of financial data analysis tools for
            clients. I have experience in a wide range of technologies, and stay
            current with the latest advancements to build clean, functional
            software using modern tools.
          </p>

          {/** Social Media Icons **/}
          <animated.div
            className="mt-3 flex gap-1"
            style={delayedSocialMediaIconsAnimation}
          >
            <animated.div
              className="mt-3 flex gap-1"
              style={socialMediaIconsAnimation}
            >
              {/** LinkedIn Icon **/}
              <a href="https://www.linkedin.com/in/jean-baptiste-bolh/">
                <svg
                  className="mr-1 h-12 w-12 hover:translate-y-1"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="LinkedIn"
                  role="img"
                  viewBox="0 0 512 512"
                  fill="#fff"
                >
                  <rect width="512" height="512" rx="15%" fill="#0077b5" />
                  <circle cx="142" cy="138" r="37" />
                  <path
                    stroke="#fff"
                    strokeWidth="66"
                    d="M244 194v198M142 194v198"
                  />
                  <path d="M276 282c0-20 13-40 36-40 24 0 33 18 33 45v105h66V279c0-61-32-89-76-89-34 0-51 19-59 32" />
                </svg>
              </a>

              {/** Upwork Icon **/}
              <a href="https://www.upwork.com/freelancers/~0148d7756f0c867d71">
                <svg
                  className="mr-1 h-12 w-12 hover:translate-y-1"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="Upwork"
                  role="img"
                  viewBox="0 0 512 512"
                >
                  <rect width="512" height="512" rx="15%" fill="#6fda44" />
                  <path
                    fill="#fff"
                    d="M357.2,296.9c-17,0-33-7.2-47.4-18.9l3.5-16.6l0.1-.6c3.2-17.6,13.1-47.2,43.8-47.2c23,0,41.7,18.7,41.7,41.7S380.2,296.9,357.2,296.9L357.2,296.9z M357.2,171.4c-39.2,0-69.5,25.4-81.9,67.3c-18.8-28.3-33.1-62.2-41.4-90.8h-42.2v109.7c0,21.7-17.6,39.3-39.3,39.3s-39.3-17.6-39.3-39.3V147.8H71v109.7c0,44.9,36.5,81.8,81.4,81.8s81.4-36.9,81.4-81.8v-18.4c8.2,17.1,18.2,34.4,30.4,49.6l-25.8,121.4h43.1l18.7-88c16.4,10.5,35.2,17.1,56.8,17.1c46.2,0,83.8-37.8,83.8-84.1C440.9,209,403.4,171.4,357.2,171.4"
                  />
                </svg>
              </a>

              {/** Github Icon **/}
              <a href="https://github.com/JeanBaptisteBolh">
                <svg
                  className="mr-1 h-12 w-12 hover:translate-y-1"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="GitHub"
                  role="img"
                  viewBox="0 0 512 512"
                >
                  <rect width="512" height="512" rx="15%" fill="#fff" />
                  <path
                    fill="#181717"
                    d="M335 499c14 0 12 17 12 17H165s-2-17 12-17c13 0 16-6 16-12l-1-44c-71 16-86-34-86-34-12-30-28-37-28-37-24-16 1-16 1-16 26 2 40 26 40 26 22 39 59 28 74 22 2-17 9-28 16-35-57-6-116-28-116-126 0-28 10-51 26-69-3-6-11-32 3-67 0 0 21-7 70 26 42-12 86-12 128 0 49-33 70-26 70-26 14 35 6 61 3 67 16 18 26 41 26 69 0 98-60 120-117 126 10 8 18 24 18 48l-1 70c0 6 3 12 16 12z"
                  />
                </svg>
              </a>
            </animated.div>
          </animated.div>
        </div>

        {/**
        <div className="shrink-0">
          <img className="h-80 w-64" src={require('../assets/programmer.jpeg')} alt="Avatar image" loading="lazy" />
        </div>
        */}
      </div>
    </div>
  );
};

export default IntroSection;
