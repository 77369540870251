import { useSpring, animated } from "react-spring";

import NavBar from "../components/NavBar";
import IntroSection from "../components/IntroSection";
import Projects from "../components/Projects";
import PastExperience from "../components/PastExperience";
import ContactForm from "../components/ContactForm";

// Ideas
// https://www.codementor.io/learn-programming/12-important-things-to-include-in-web-dev-portfolios
// https://alvarotrigo.com/blog/web-developer-portfolio-examples/

// React page sliding https://www.npmjs.com/package/react-router-page-transition
// React page scrolling https://www.youtube.com/watch?v=OAx0we521sk

//font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";

export const useSlideInAnimation = (delay) => {
  const DURATION = 200;

  return useSpring({
    from: { opacity: 0, transform: "translateX(-20px)" },
    to: { opacity: 1, transform: "translateX(0)" },
    delay: DURATION * delay,
    config: { duration: DURATION },
  });
};

const Home = () => {
  return (
    <div className="bg-slate-900 text-gray-100 sm:h-max">
      <animated.div style={useSlideInAnimation(0)}>
        <NavBar />
      </animated.div>

      <animated.div style={useSlideInAnimation(1)}>
        <IntroSection />
      </animated.div>

      <animated.div style={useSlideInAnimation(2)}>
        <Projects />
      </animated.div>

      <animated.div style={useSlideInAnimation(3)}>
        <PastExperience />
      </animated.div>

      <animated.div style={useSlideInAnimation(4)}>
        <ContactForm />
      </animated.div>
    </div>
  );
};

export default Home;
