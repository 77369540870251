import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  // Message to be displayed above the form after form submission
  const [successMsg, setSuccessMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendEmail = (formData) => {
    emailjs
      .send("service_oq9sblu", "contact_form", formData, "PGXqTNekpYZ84kfNw")
      .then(
        (result) => {
          console.log(result.text);
          setSuccessMsg("Thanks!  I'll get back to you shortly.");
        },
        (error) => {
          console.log(error.text);
          setSuccessMsg("The email didn't send for some reason.");
        }
      );
  };

  return (
    <div className="grid place-items-center mx-auto px-3 py-6">
      <h3 className="py-4 text-center text-xl font-bold">
        Interested in working together? <span className="bg-gradient-to-br from-sky-500 to-cyan-400 bg-clip-text text-transparent">Let's talk.</span>
      </h3>

      <form
        onSubmit={handleSubmit((data) => {
          console.log(data);
          sendEmail(data);
        })}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2">
          {/** NAME FIELD **/}
          <div className="col-span-2 sm:col-span-1 mx-2 my-1">
            <input
              {...register("name", { required: "Please enter your name." })}
              className="w-full bg-zinc-200 text-zinc-800 rounded px-2 py-2"
              type="text"
              name="name"
              placeholder="Name..."
            />
            <p className="text-red-500 text-xs italic py-1">
              {errors.name?.message}
            </p>
          </div>

          {/** EMAIL FIELD **/}
          <div className="col-span-2 sm:col-span-1 mx-2 my-1">
            <input
              {...register("email", { required: "Please enter your email." })}
              className="w-full bg-zinc-200 text-zinc-800 rounded px-2 py-2"
              type="email"
              name="email"
              placeholder="Email..."
            />
            <p className="text-red-500 text-xs italic py-1">
              {errors.email?.message}
            </p>
          </div>

          {/** MESSAGE FIELD **/}
          <div className="col-span-2 mx-2 my-1">
            <textarea
              {...register("message", { required: "Please enter a message." })}
              className="w-full block bg-zinc-200 text-zinc-800 rounded h-36 px-2 py-2"
              name="message"
              placeholder="Your message..."
            />
            <p className="text-red-500 text-xs italic py-1">
              {errors.message?.message}
            </p>
          </div>

          {/** FORM SEND SUCCESS MESSAGE **/}
          {successMsg.length > 0 && (
            <div className="col-span-2 mx-2">
              <p className="w-full text-zinc-200 text-xs italic py-1">
                {successMsg}
              </p>
            </div>
          )}

          {/** SEND BUTTON **/}
          <div className="col-span-2 mx-2 my-2">
            <button
              className="w-full bg-zinc-200 hover:bg-cyan-400 text-zinc-800 font-bold px-4 py-2 rounded"
              type="submit"
              value="Send"
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
